export function FormatDate(param) {
	return new Date(param).toLocaleString("pt-br", {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	});
}

export function FormatDateOnly(param) {
	return new Date(param).toLocaleString("pt-br", {
		year: "numeric",
		month: "numeric",
		day: "numeric",
	});
}

const slugify = require("slugify");
export function FormatToUrl(param) {
	return slugify(
		(param).replaceAll("ª", "").replaceAll("º", ""),
		{ remove: /[[\]{}§º°|ª¹²³¬?*+¨^~.\\/()'"!:@]/g, lower: true }
	);
}
