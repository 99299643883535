/* eslint-disable react/prop-types */
import React from "react";

/**
 * Componente para renderizar conteúdos HTML crus.
 */
export default function RawComponent({ content }) {
	return (
		<div
			dangerouslySetInnerHTML={{
				__html: content,
			}}
		></div>
	);
}
